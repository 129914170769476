import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionDetailsComponent } from './Core/collection-details/collection-details.component';
import { ContributorsComponent } from './Core/contributors/contributors.component';
import { ExploreComponent } from './Core/explore/explore.component';
import { HomeComponent } from './Core/home/home.component';
import { NotfoundComponent } from './Core/notfound/notfound.component';
import { SearchResultComponent } from './Core/search-result/search-result.component';
import { TermsComponent } from './Core/terms/terms.component';
import { AuthGuard } from './Guard/auth.guard';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    data: { title: "Home | Orbzone" },
  },

  {
    path: 'home', redirectTo: ''
  },

  {
    path: 'explore', 
    component: ExploreComponent, 
    data: {title: 'Explore | Orbzone'}
  },

  {
    path: 'explore/:slug', 
    component: CollectionDetailsComponent, 
    data: {title: 'Explore Details | Orbzone'}
  },

  {
    path: 'contributors', 
    component: ContributorsComponent, 
    data: {title: 'Contributors | Orbzone'}
  },

  {
    path: 'terms', 
    component: TermsComponent, 
    data: {title: 'Terms and Conditions | Orbzone'}
  },

  {
    path: 'search', 
    component: SearchResultComponent, 
    data: {title: 'Search Results | Orbzone'}
  },

  {
    path: '404', 
    component: NotfoundComponent, 
    data: {title: 'Not Found | Orbzone'}
  },

  {
    path: "user",
    canActivate: [AuthGuard],
    data: {
      title: "User Dashboard | Orbzone",
    },
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "account",
    data: {
      title: "User Account | Orbzone",
    },
    loadChildren: () =>
      import("./onboarding/onboarding.module").then((m) => m.OnboardingModule),
  },
  {path: '**', redirectTo: '/404'},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
