import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/Services/util.service';
import { tabs } from '../tabs';
import { ApiService } from './../../Services/api.service';
import { DateService } from './../../Services/date.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements OnInit {
  gallery = [];
  tabs = tabs;
  loaderPopular = false;
  loaderRandom = false;
  loaderRecommended = false;
  active_tab = 'Home';
  collections = [];
  random = [];
  popular = [];
  recommended = [];
  filter = {
    duration: 'all',
  };
  shimmer = false;
  params = {
    name: '',
    start: '',
    end: '',
  };
  options = ['week', 'all', 'month', 'year'];
  pagination = {
    current: 1,
    from: null,
    to: null,
    per_page: null,
    total: null,
  };
  constructor(
    private matDialog: MatDialog,
    private util: UtilService,
    private api: ApiService,
    private router: Router,
  ) { }

  ngOnInit(): any {
    this.checkStore();
  }
  changeTab(item: string): void {
    this.active_tab = item;
  }
  checkStore(): void {
    this.util.popularData.subscribe((data) => {
      if (data) {
        this.popular = data;
      }
      else {
        this.popularCollections();
      }
    });
    this.util.recommendedData.subscribe((data) => {
      if (data) {
        this.recommended = data;
      } else {
        this.recommendedCollections();
      }
    });
    this.util.randomData.subscribe((data) => {
      if (data) {
        this.random = data;
      } else {
        this.randomCollections();
      }
    });
  }
  async popularCollections(): Promise<any> {
    this.loaderPopular = true;
    this.api.popularCollections().subscribe((res: any) => {
      this.util.popularCollections(res.data.collections);
      this.loaderPopular = false;
    });
  }
  async recommendedCollections(): Promise<any> {
    this.loaderRecommended = true;
    this.api.recommendedCollections().subscribe((res: any) => {
      this.util.recommendedCollections(res.data.collections);
      this.loaderRecommended = false;
    });
  }

  async randomCollections(): Promise<any> {
    this.loaderRandom = true;
    this.api.randomCollections().subscribe((res: any) => {
      this.util.randomCollections(res.data.collections);
      this.loaderRandom = false;
    });
  }

  setCollection(item: any): void {
    const slug = item.name.trim().replace(/\s+/g, '-'); // remove white space from collection name and replaced with '-';
    this.util.setCollection(item);
    this.router.navigate(['/explore', slug]);
  }
}
