export const faq = [
  {
    id: 1,
    question: 'HOW MUCH WILL CONTRIBUTORS BE PAID ON ORBZONE?',
    answer: `Contributors will be paid 75% of what we charge customers for each of their photos.`
  },
  {
    id: 2,
    question: 'HOW DO CONTRIBUTORS GET PAID FOR THEIR PHOTOS ?',
    answer: `For every time a photo is bought, the contributor's online wallet will be credited. Withdrawal can be made from the dashboard.`
  },
  {
    id: 3,
    question: 'HOW DO CONTRIBUTORS MONITOR DOWNLOADS AND THE MONEY THEY’VE MADE ON ORBZONE?',
    answer: `Contributors can monitortheir activities on their unique dashboards.`
  },
  {
    id: 4,
    question: `CAN CONNTRIBUTORS SET THE PRICE ON THEIR PHOTOS?`,
    answer: `Unfortunately, contributors are not allowed to set the price of their photos.`
  }
];
