import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { gallery } from './../gallery';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.url;
  // errorHandler(error: HttpErrorResponse) {
  //   return throwError(error.message || "serv");
  // }
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  downloadFile(url, name): any {
    this.spinner.show();
    fetch(`https://cors-anywhere.herokuapp.com/${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
    })
      .then(response => response.blob())
      .then(response => {
        const blob = new Blob([response], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        // if(!name.toLowerCase().includes('jpg' || 'png' || 'svg' || 'jpeg' || '.webp')){
        //   name = name + '.png'
        // }
        a.download = `orbzone - ${name}`;
        document.body.appendChild(a);
        a.click();
        this.spinner.hide();
      });
  }
  profile(): any {
    const getUrl = this.baseUrl + 'profile';
    return this.http.get(getUrl)
      .pipe(retry(1), tap()
      );
  }
  updateImage(data: FormData): Observable<object> {
    const getUrl = this.baseUrl + 'save-profile-image';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }
  updateProfile(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'edit-profile';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }
  updatePassword(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'change-password';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }
  signup(data: object): any {
    const getUrl = this.baseUrl + 'sign-up';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  login(data: object): any {
    const getUrl = this.baseUrl + 'sign-in';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }
  resetPassword(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'start-forgot-password';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  confirmCode(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'confirm-phone-code';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  resendCode(): Observable<object> {
    const getUrl = this.baseUrl + 'resend-phone-code';
    return this.http.get(getUrl, {})
      .pipe(tap()
      );
  }

  setNewPassword(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'finish-forgot-password';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }


  // IMAGE APIS
  publicImage(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'search-for-images';
    return this.http.post(getUrl, data).pipe(retry(1), tap());
  }

  uploadImage(data: FormData): Observable<object> {
    const getUrl = this.baseUrl + 'add-image';
    return this.http.post(getUrl, data).pipe(tap());
  }

  uploadVideo(data: FormData): Observable<object> {
    const getUrl = this.baseUrl + 'add-video';
    return this.http.post(getUrl, data).pipe(tap());
  }

  myLibrary(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'library';
    return this.http.post(getUrl, data).pipe(retry(1), tap());
  }

  myContributions(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'contributions';
    return this.http.post(getUrl, data).pipe(retry(1), tap());
  }

  editImage(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'update-image';
    return this.http.post(getUrl, data).pipe(tap());
  }

  buyImage(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'buy-image';
    return this.http.post(getUrl, data).pipe(tap());
  }

  likeImage(data: object): Promise<object> {
    const getUrl = this.baseUrl + 'like-image';
    return this.http.post(getUrl, data).toPromise();
  }

  unlikeImage(data: object): Promise<object> {
    const getUrl = this.baseUrl + 'unlike-image';
    return this.http.post(getUrl, data).toPromise();
  }

  checkLicence(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'download-full-image';
    return this.http.post(getUrl, data).pipe(tap());
  }

  downloadImg(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'download-image';
    return this.http.get(getUrl).pipe(tap());
  }

  deleteImage(data: object): Promise<object> {
    const getUrl = this.baseUrl + 'delete-image';
    return this.http.post(getUrl, data).toPromise();
  }

  deleteImages(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'delete-images';
    return this.http.post(getUrl, data).pipe(tap());
  }

  relatedImages(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'similar-images';
    return this.http.post(getUrl, data)
      .pipe(retry(2), tap()
      );
  }

  imageDetails(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'image-details';
    return this.http.post(getUrl, data)
      .pipe(retry(2), tap()
      );
  }

  publicImageDetails(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'public-image-details';
    return this.http.post(getUrl, data)
      .pipe(retry(2), tap()
      );
  }

  searchImage(data: object, page: number): Observable<object> {
    const getUrl = this.baseUrl + `search-for-images?page=${page}`;
    return this.http.post(getUrl, data)
      .pipe(retry(1), tap()
      );
  }

  dashboard(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'dashboard';
    return this.http.post(getUrl, data)
      .pipe(retry(1), tap()
      );
  }

  categories(): Observable<object> {
    const getUrl = this.baseUrl + 'categories';
    return this.http.get(getUrl)
      .pipe(retry(1), tap()
      );
  }


  myWithdrawals(): Observable<object> {
    const getUrl = this.baseUrl + 'withdrawals';
    return this.http.post(getUrl, {})
      .pipe(retry(1), tap()
      );
  }


  // ACCOUNT APIS
  addAccount(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'add-bank-account';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  resolveAccount(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'resolve-account';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  deleteAccount(data: object): Promise<object> {
    const getUrl = this.baseUrl + 'delete-bank-account';
    return this.http.post(getUrl, data).toPromise();
  }

  deleteCollection(data: object): Promise<object> {
    const getUrl = this.baseUrl + 'delete-collection';
    return this.http.post(getUrl, data).toPromise();
  }

  defaultAccount(data: object): any {
    const getUrl = this.baseUrl + 'make-account-default';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  withdrawal(data: object): any {
    const getUrl = this.baseUrl + 'withdraw';
    return this.http.post(getUrl, data)
      .pipe(retry(2), tap()
      );
  }

  startCardAddition(): any {
    const getUrl = this.baseUrl + 'start-add-card';
    return this.http.get(getUrl)
      .pipe(retry(2), tap()
      );
  }

  finishAddCard(data: object): any {
    const getUrl = this.baseUrl + 'finish-add-card';
    return this.http.post(getUrl, data)
      .pipe(retry(1), tap()
      );
  }

  deleteCard(data: object): any {
    const getUrl = this.baseUrl + 'delete-card';
    return this.http.post(getUrl, data).toPromise();
  }

  // Collections
  createCollection(data: object): any {
    const getUrl = this.baseUrl + 'add-collection';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  editCollection(data: object): any {
    const getUrl = this.baseUrl + 'edit-collection';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  addToCollection(data: object): any {
    const getUrl = this.baseUrl + 'add-collection-image';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  myCollections(): any {
    const getUrl = this.baseUrl + 'collections';
    return this.http.get(getUrl)
      .pipe(retry(1), tap()
      );
  }
  recommendedCollections(): Observable<any>{
    const getUrl = this.baseUrl + 'recommended-collections';
    return this.http.get(getUrl)
      .pipe(retry(1), tap()
      );
  }
  randomCollections(): Observable<any>{
    const getUrl = this.baseUrl + 'random-collections';
    return this.http.get(getUrl)
      .pipe(retry(1), tap()
      );
  }
  popularCollections(): Observable<any>{
    const getUrl = this.baseUrl + 'popular-collections';
    return this.http.get(getUrl)
      .pipe(retry(1), tap()
      );
  }
  collectionDetails(data: object): any {
    const getUrl = this.baseUrl + 'collection-details';
    return this.http.post(getUrl, data)
      .pipe(retry(1), tap()
      );
  }
}
