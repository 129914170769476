import { Component, OnInit } from '@angular/core';
import { AddCollectionComponent } from 'src/app/helper/add-collection/add-collection.component';
import { ImagePreviewDioalgComponent } from 'src/app/helper/image-preview-dioalg/image-preview-dioalg.component';
import { LikeUnlikeComponent } from 'src/app/helper/like-unlike/like-unlike.component';
import { ShareComponent } from 'src/app/helper/share/share.component';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.scss']
})
export class CollectionDetailsComponent implements OnInit {
  collections = [];
  name = '';
  constructor(
    private util: UtilService
  ) {
    
  }

  ngOnInit(): void {
    this.collections = this.util.getCollection().initial_images;
    this.name = this.util.getCollection().name;
  }
  open(item: object): void {
    this.util.open(item, ImagePreviewDioalgComponent);
  }
  addCollection(item: object): any {
    this.util.addCollection(item, this.util.checkRoute(), AddCollectionComponent);
  }
  share(item: object): void {
    this.util.share(item, ShareComponent);
  }
  like(item): void {
    const data = {
      title: item.name,
      id: item.imid,
      action: item.i_like === true ? 'unlike' : 'like'
    };
    this.util.likeImage(data, this.util.checkRoute(), LikeUnlikeComponent);
  }
}
