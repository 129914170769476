<mat-dialog-content>
  <div class="top d-flex justify-content-between mb-3 flex-wrap">
    <h6 class="f-16 fw-800 text-app-black f-18">Save to Collection</h6>
    <div class="filter_box cursor d-flex">
      <!-- <a class="btn_grey" (click)="toggleForm()" *ngIf="!create_form">Create Collection</a> -->
      <a class="btn_grey mr-2" (click)="close()">Cancel</a>
      <a
        class="btn_grey f-14"
        (click)="CREATE_FORM = false; EXISTING_FORM = true"
        *ngIf="CREATE_FORM"
        >Add to Existing Collection
      </a>
      <a
        class="btn_grey f-14"
        (click)="CREATE_FORM = true; EXISTING_FORM = false"
        *ngIf="EXISTING_FORM"
        >New Collection
      </a>
    </div>
  </div>

  <div class="create_form">
    <div class="mb-3 image_data">
      <img
        [src]="data?.preview"
        onerror="this.src='../../../assets/img/unavailable-image.jpg'"
        alt="Orbzone gallery"
        class="w-100"
      />
    </div>
    <form class="new_form border" *ngIf="CREATE_FORM" [formGroup]="newForm">
      <div class="form-group">
        <label for="title" class="f-14 fw-800 mb-2">Title</label>
        <input
          type="text"
          id="title"
          class="form-control"
          formControlName="name"
        />
      </div>
      <div class="form-group mb-3">
        <label for="title" class="f-14 fw-800 mb-2">Select Category</label>
        <select
          name="form-control"
          id=""
          name="collection"
          formControlName="category"
        >
          <option value="" disabled selected>select category</option>
          <option [value]="item" *ngFor="let item of categories">
            {{ item | titlecase }}
          </option>
        </select>
      </div>
      <div class="text-right mt-2">
        <button
          class="cursor btn bg-app-black text-white"
          (click)="create()"
          [disabled]="!newForm.valid || loader"
        >
          Create Collection
          <i class="fas fa-circle-notch fa-spin ml-2" *ngIf="loader"></i>
        </button>
      </div>
    </form>
    <form
      class="new_form border"
      *ngIf="EXISTING_FORM"
      [formGroup]="collectionForm"
    >
      <label for="title" class="f-14 fw-800 mb-2">Select collection</label>
      <select name="form-control" id="" name="collection" formControlName="id">
        <option value="" disabled selected>select collection</option>
        <option [value]="item?.id" *ngFor="let item of collections">
          {{ item?.name | titlecase }}
        </option>
      </select>
      <div class="text-right mt-2">
        <button
          class="cursor btn bg-app-black text-white"
          (click)="startAddCollection()"
          [disabled]="!collectionForm.valid || loader"
        >
          Save to Collection
          <i class="fas fa-circle-notch fa-spin ml-2" *ngIf="loader"></i>
        </button>
      </div>
    </form>
  </div>

  <div class="bottom f-14 text-app-black cursor font-weight-bold">
    <a href="user/#collection" style="color: #333"> View Your Collections </a>
    <mat-icon class="ml-1">east</mat-icon>
  </div>
</mat-dialog-content>
