<mat-dialog-content>
    <div class="top bg-app-altBlack text-white text-center">
        {{data?.action | titlecase}} {{data?.title}}
    </div>
    <div class="content text-center">
        <p class="text-app-grey mb-4">
            Are you sure you want to {{data?.action | titlecase}} this Image 
        </p>
        <div class="d-flex justify-content-center">
            <button class="btn border-black radius_3 text-app-black mr-3"
                (click)="cancel()">Cancel
            </button>
            <button class="btn bg-app-black radius_3 text-white"
                [disabled]="loader" (click)="confirm()">Yes
                <i class="fas fa-circle-notch fa-spin ml-3" *ngIf="loader"></i>
            </button>
        </div>
    </div>
    
</mat-dialog-content>