<nav class="nav_bar flex_center" [ngClass]="{ nav_black: navViewPort == true }">
  <div class="logo_brand flex_center">
    <img
      src="../../../assets/img/logo.png"
      alt=""
      [routerLink]="['']"
      class="cursor"
    />
    <div class="search desktop_view" *ngIf="show_search">
      <input
        type="text"
        placeholder="Search for premium photos and videos"
        [(ngModel)]="search_term"
        (keyup.enter)="search()"
      />
      <mat-icon (click)="search()" class="cursor" *ngIf="search_term.length > 1"
        >search</mat-icon
      >
    </div>
  </div>
  <div class="flex_center">
    <div class="links flex_center desktop_view">
      <li [routerLink]="['/explore']" routerLinkActive="router_active">
        Explore
      </li>
      <li [routerLink]="['/contributors']">Contributors</li>
      <li (click)="upload()">Upload</li>
      <li *ngIf="!logged_In" routerLink="/account/login">Sign In</li>
      <li *ngIf="!logged_In">
        <button class="btn bg-app-primary" [routerLink]="['/account/join']">
          Join
        </button>
      </li>
    </div>
    <div class="flex_center">
      <div *ngIf="logged_In" class="flex_center">
        <img
          [src]="user?.image"
          onerror="this.src='../../../assets/img/user-icon.jpg'"
          alt="Orbzone Contributor"
          class="user_avatar"
          [routerLink]="['/user']"
        />
        <div class="dn" *ngIf="logged_In">
          <li class="nav-item" role="presentation" (click)="logOut()">
            <a href="" class="nav-link logout text-app-black"
              ><svg
                width="30px"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sign-out-alt"
                class="svg-inline--fa fa-sign-out-alt fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="white"
                  d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                ></path>
              </svg>
              <span class="ml-2">Sign Out</span>
            </a>
            <!-- <button class="btn bg-app-black text-white mt-3">Logout</button> -->
          </li>
        </div>
      </div>
      <div class="menu_toggle menu_open">
        <mat-icon (click)="menu()" class="cursor">menu</mat-icon>
      </div>
    </div>
  </div>
</nav>
<div class="mobile_menu bg-app-light slide_menu" id="menu">
  <div class="logo_brand flex_center">
    <img
      src="../../../assets/img/favicon.png"
      alt=""
      [routerLink]="['']"
      class="cursor"
    />
    <div class="search px-4 w-100">
      <input
        type="text"
        placeholder="Search for premium photos and
                videos"
        [(ngModel)]="search_term"
        (keyup.enter)="search()"
      />
      <mat-icon (click)="search()" class="cursor" *ngIf="search_term.length > 1"
        >search</mat-icon
      >
    </div>
    <div class="menu_open">
      <mat-icon (click)="menu()" class="cursor">menu</mat-icon>
    </div>
  </div>
  <div class="mobile_links mt-5">
    <li routerLink="/explore">Explore</li>
    <li [routerLink]="['/contributors']">Contributors</li>
    <li (click)="upload()">Upload</li>
    <li *ngIf="!logged_In" routerLink="/account/login">Sign In</li>
    <li *ngIf="!logged_In">
      <button class="btn bg-app-primary" [routerLink]="['/account/join']">
        Join
      </button>
    </li>
    <li
      *ngIf="logged_In"
      class="nav-item"
      role="presentation"
      (click)="logOut()"
    >
      <a href="" class="nav-link logout text-app-black"
        ><svg
          width="30px"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="sign-out-alt"
          class="svg-inline--fa fa-sign-out-alt fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="white"
            d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
          ></path>
        </svg>
        <span class="ml-2">Sign Out</span>
      </a>
      <!-- <button class="btn bg-app-black text-white mt-3">Logout</button> -->
    </li>
  </div>
</div>
