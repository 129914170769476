<div id="scroll_detector"></div>
<app-navbar></app-navbar>
<div class="banner position-relative">
  <span class="author-tag text-white cursor f-14">Photo by Okanu Frank</span>
  <div class="text_content banner_typo">
    <h6 class="text-white">
      Premium stock photos & videos shared by african contributors.
    </h6>
    <div class="search_area" *ngIf="show_search">
      <div class="position-relative">
        <input
          type="text"
          name="searchterm"
          id=""
          placeholder="Search for premium photos and videos"
          (keyup.enter)="enter()"
          [(ngModel)]="search_term"
        />
        <mat-icon
          (click)="search()"
          class="cursor text-app-primary"
          *ngIf="search_term.length > 1"
          >search
        </mat-icon>
      </div>
      <div class="tags">
        <span>Suggested : </span>
        <span class="ml-2">
          <small
            class="mr-2 cursor"
            *ngFor="let item of categories"
            (click)="search_term = item; search()"
            >{{ item | titlecase }},
          </small>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="image_tabs">
  <span
    [ngClass]="{ active_tab: active_tab == item }"
    class="f-17 text-app-dark fw-800"
    *ngFor="let item of tabs"
    (click)="changeTab(item)"
    >{{ item | titlecase }}
  </span>
</div>
<div class="intro_text">
  <h6 class="f-16 font-weight-bold">Premium Stock Images</h6>
</div>

<ngx-masonry [options]="{ gutter: 14, originLeft: true }">
  <div
    ngxMasonryItem
    class="masonry-item"
    *ngFor="let item of gallery; let i = index"
  >
    <img
      (click)="open(item)"
      [src]="item?.preview"
      class="grid_image w-100 cursor"
      (load)="finishedLoading($event, i)"
      onerror="this.src='../../../assets/img/unavailable-image.jpg'"
      alt="Orbzone gallery"
    />
    <div class="grid__body cursor"></div>
    <div class="bottom_panel mt-auto">
      <div class="avatar cursor d-flex align-items-center" (click)="open(item)">
        <img
          [src]="item?.user?.image"
          onerror="this.src='../../../assets/img/user-icon.jpg'"
          alt="Orbzone Author"
        />
        <span class="ml-3 f-14 text-white"
          >{{ item?.user?.surname | titlecase }}
          {{ item?.user?.firstname | titlecase }}</span
        >
      </div>
      <div class="icons d-flex">
        <mat-icon (click)="share(item)">share</mat-icon>
        <mat-icon (click)="addCollection(item)">add_circle_outline</mat-icon>
        <mat-icon (click)="like(item)">favorite_border</mat-icon>
      </div>
    </div>
  </div>
</ngx-masonry>

<div
  class="text-center mb-5"
  *ngIf="gallery.length > 0 && !shimmer && imageLoaded"
>
  <span class="text-app-black f-14 cursor load_more" (click)="loadMore()"
    >Load More</span
  >
</div>

<app-shimmer *ngIf="shimmer || !imageLoaded"></app-shimmer>
<app-footer></app-footer>
