import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AddCollectionComponent } from 'src/app/helper/add-collection/add-collection.component';
import { ImagePreviewDioalgComponent } from 'src/app/helper/image-preview-dioalg/image-preview-dioalg.component';
import { LikeUnlikeComponent } from 'src/app/helper/like-unlike/like-unlike.component';
import { ShareComponent } from 'src/app/helper/share/share.component';
import { UtilService } from 'src/app/Services/util.service';
import { ApiService } from './../../Services/api.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  gallery = [];
  search_term = '';
  shimmer = false;
  params = {
    name: '',
    start: '',
    end: ''
  };
  pagination = {
    current: 1,
    from: null,
    to: null,
    per_page: null,
    total: null
  };
  constructor(
    private matDialog: MatDialog,
    private util: UtilService,
    private router: Router,
    private api: ApiService,
    private titleService: Title,
    private Activatedroute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.searchmages(1);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd === true) {
        this.searchmages(1);
      }
    });
  }
  calcPage(): boolean {
    const pages = this.pagination.total / this.pagination.per_page;
    if (pages >= this.pagination.current) {
      return true;
    }
  }

  loadMore(): void {
    this.searchmages(this.pagination.current + 1);
  }
  searchmages(page): void {
    this.shimmer = true;
    this.params.name = this.Activatedroute.snapshot.queryParamMap.get('tag');
    this.titleService.setTitle(`Interesting ${this.params.name} Photos | Orbzone`);
    this.api.searchImage(this.params, page).subscribe((res: any) => {
      if (page !== 1) {
        const data = this.gallery.concat(res.data.images.data);
        this.gallery = data;
      }
      if (page === 1) {
        this.gallery = res.data.images.data;
      }
      this.pagination = {
        current: res.data.images.current_page,
        per_page: res.data.images.per_page,
        to: res.data.images.current_page,
        from: res.data.images.from,
        total: res.data.images.total
      };
      this.shimmer = false;
    }, err => (
      this.shimmer = false
    ));
  }

  open(item: object): void {
    this.util.open(item, ImagePreviewDioalgComponent);
  }
  addCollection(item: object): void {
    this.util.addCollection(item, this.util.checkRoute(), AddCollectionComponent);
  }
  share(item: object): void {
    this.util.share(item, ShareComponent);
  }
  like(item: any): void {
    const data = {
      title: item.name,
      id: item.imid,
      action: item.i_like === true ? 'unlike' : 'like'
    };
    this.util.likeImage(data, this.util.checkRoute(), LikeUnlikeComponent);
  }
}