<app-alt-navbar></app-alt-navbar>

<div class="banner row mx-0">
  <div class="col-md-6 px-0">
    <h6 class="f-48 text-app-altBlack fw-800">
      {{ name | titlecase }}
    </h6>
  </div>
</div>

<ngx-masonry [options]="{ gutter: 14, originLeft: true }">
  <div ngxMasonryItem class="masonry-item" *ngFor="let item of collections">
    <img
      [src]="item"
      class="grid_image"
      onerror="this.src='../../../assets/img/unavailable-image.jpg'"
      alt="Orbzone gallery"
    />
    <div class="grid__body cursor">
      <!-- (click)="open(item?.image)" -->
    </div>
    <!-- <div class="bottom_panel mt-auto">
            <div class="avatar cursor d-flex align-items-center"
                (click)="open(item?.image)">
                <img [src]="item?.image?.user?.image"
                    onerror="this.src='../../../assets/img/user-icon.jpg'"
                    alt="Orbzone Author">
                <span class="ml-3 f-14 text-white">{{item?.image?.user?.surname}}
                    {{item?.image?.user?.firstname}}</span>
            </div>
            <div class="icons d-flex">
                <mat-icon (click)="share(item?.image)">share</mat-icon>
                <mat-icon (click)="addCollection(item?.image)">add_circle_outline</mat-icon>
                <mat-icon (click)="like(item?.image)">favorite_border</mat-icon>
            </div>
        </div> -->
  </div>
</ngx-masonry>

<app-footer></app-footer>
