<div class="container">
    <h6 class="text-app-primary">Oops!</h6>
    <h5>404 - PAGE NOT FOUND</h5>
    <P class="text-center">
        The page you are looking for does not exist <br> 
        or is temporarily unavailable
    </P>
    <div class="text-center">
        <a class="bg-app-primary btn" (click)="dashboard()">Go to Homepage</a>
    </div>
    
</div>