<div class="notification" *ngIf="notification">
  <div class="panel">
    <div class="my-3 text-right">
      <mat-icon class="cursor" (click)="notification = false">close</mat-icon>
    </div>
    <div class="content text-center">
      <h6 class="font-weight-bold f-24 text-app-black">Welcome to Orbzone</h6>
      <p class="text-app-black f-14">
        Orbzone is a platform that provides a networked environment for African
        photographers to share their work with the world.
      </p>
      <p class="text-app-black f-14">
        We enable creative content creators to gain access to better and more
        relatable content that will help them tell the African story even better
        as a business or brand.
      </p>
      <div class="button_group text-center font-weight-bold">
        <a
          class="bg-app-black text-white btn cursor f-16"
          [routerLink]="['/contributors']"
          (click)="notification = false"
          >For Contributor’s</a
        >
        <a
          class="ml-3 btn_border text-app-black btn cursor f-16"
          (click)="notification = false"
          >I want Premium Images</a
        >
      </div>
    </div>
  </div>
</div>

<!-- <div class="notification text-center" *ngIf="notification">
    <p class="text-white">...Processing your download</p>
</div> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <span class="text-white">Processing, please wait...</span>
</ngx-spinner>
<router-outlet></router-outlet>
