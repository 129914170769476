<div class="shimmer_wrapper" >
    <div class="shimmer_card" >
        <div class="shimmerBG media"></div>
        <div class="p-32 d-flex">
            <div class="shimmer_user">
                <div class="shimmerBG avatar"></div>
                <div class="shimmerBG user"></div>
            </div>
            <div class="shimnmer_icons">
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
            </div>
        </div>
    </div>
    <div class="shimmer_card" >
        <div class="shimmerBG media"></div>
        <div class="p-32 d-flex">
            <div class="shimmer_user">
                <div class="shimmerBG avatar"></div>
                <div class="shimmerBG user"></div>
            </div>
            <div class="shimnmer_icons">
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
            </div>
        </div>
    </div>
    <div class="shimmer_card" >
        <div class="shimmerBG media"></div>
        <div class="p-32 d-flex">
            <div class="shimmer_user">
                <div class="shimmerBG avatar"></div>
                <div class="shimmerBG user"></div>
            </div>
            <div class="shimnmer_icons">
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
            </div>
        </div>
    </div>
    <div class="shimmer_card" >
        <div class="shimmerBG media"></div>
        <div class="p-32 d-flex">
            <div class="shimmer_user">
                <div class="shimmerBG avatar"></div>
                <div class="shimmerBG user"></div>
            </div>
            <div class="shimnmer_icons">
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
            </div>
        </div>
    </div>
    <div class="shimmer_card" >
        <div class="shimmerBG media"></div>
        <div class="p-32 d-flex">
            <div class="shimmer_user">
                <div class="shimmerBG avatar"></div>
                <div class="shimmerBG user"></div>
            </div>
            <div class="shimnmer_icons">
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
            </div>
        </div>
    </div>
    <div class="shimmer_card" >
        <div class="shimmerBG media"></div>
        <div class="p-32 d-flex">
            <div class="shimmer_user">
                <div class="shimmerBG avatar"></div>
                <div class="shimmerBG user"></div>
            </div>
            <div class="shimnmer_icons">
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
                <div class="shimmerBG icons"></div>
            </div>
        </div>
    </div>
  </div>