<app-alt-navbar></app-alt-navbar>
<div class="banner row mx-0">
  <div class="col-md-6 px-0">
    <h6 class="f-48 text-app-altBlack fw-800">Search Results</h6>
    <p class="f-16 fw-600">
      Displaying {{ gallery?.length }} Match(es) with the tag "{{
        params?.name
      }}"
    </p>
  </div>
</div>

<div class="intro_text d-flex justify-content-between">
  <h6 class="f-16 fw-800 text-app-black f-18">Premium Stock Images</h6>
  <div class="filter_box cursor">
    <!-- <h6 class="f-16 fw-800 text-app-black f-18">Recent</h6>
        <mat-icon>expand_more</mat-icon> -->
  </div>
</div>
<ngx-masonry [options]="{ gutter: 14, originLeft: true }">
  <div ngxMasonryItem class="masonry-item" *ngFor="let item of gallery">
    <img
      [src]="item?.preview"
      class="grid_image cursor"
      (click)="open(item)"
      onerror="this.src='../../../assets/img/unavailable-image.jpg'"
      alt="Orbzone gallery"
    />
    <div class="grid__body cursor"></div>
    <div class="bottom_panel mt-auto">
      <div class="avatar cursor d-flex align-items-center" (click)="open(item)">
        <img
          [src]="item?.user?.image"
          onerror="this.src='../../../assets/img/user-icon.jpg'"
          alt="Orbzone Author"
        />
        <span class="ml-3 f-14 text-white"
          >{{ item?.user?.surname }} {{ item?.user?.firstname }}</span
        >
      </div>
      <div class="icons d-flex">
        <mat-icon (click)="share(item)">share</mat-icon>
        <mat-icon (click)="addCollection(item)">add_circle_outline</mat-icon>
        <mat-icon (click)="like(item)">favorite_border</mat-icon>
      </div>
    </div>
  </div>
</ngx-masonry>

<div class="text-center mb-5" *ngIf="gallery.length > 0 && !shimmer">
  <span class="text-app-black f-14 cursor load_more" (click)="loadMore()"
    >Load More</span
  >
</div>

<div class="py-5 text-center" *ngIf="gallery.length == 0!; shimmer">
  <h6 class="text-app-black f-24 fw-800">
    We Couldn't Find Anything For "{{ params?.name }}"
  </h6>
  <p class="text-app-black f-18 fw-800">
    Please enter another keyword and try again
  </p>
</div>
<app-shimmer *ngIf="shimmer"></app-shimmer>
<app-footer></app-footer>
