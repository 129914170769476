<app-alt-navbar></app-alt-navbar>
<div class="dark_theme bg-app-altBlack">
    <div class="banner row mx-0 mb_10">
        <div class="col-md-7 text_area text-white mb-4 mb-md-0 mb-lg-0">
            <h6 class="f-48 text-a fw-800 mb_2">
                Become a Contributor
            </h6>
            <p class="f-18 fw-600 mb_2">
                We love teaming up with other creators. Here’s how you can work
                with us.
            </p>
            <button class="bg-app-primary btn text-white mb_2"
                [routerLink]="['/account/join']">Get Started
            </button>
            <div class="specs f-16 mb_4">
                <p>Share your amazing photos on Orbzone</p>
                <p>Get paid for what you love to do</p>
                <p>Over hundreds of image views</p>
                <p>Sponsored events</p>
                <p>Photo Challenges</p>
            </div>
            <div>
                <p class="fw-600 f-18">Here are a few guidelines <br>
                    to make your time with us <br>
                    amazing!
                </p>
                <p class="text-app-grey f-16">We promise, you won't sleep off
                    while
                    reading through.
                </p>
            </div>
        </div>
        <div class="col-md-5 px-0">
            <div class="images">
                <div class="column_1 mt_1">
                    <div class="mb_1">
                        <img src="../../../assets/img/gallery-4.webp" alt=""
                            class="img-fluid">
                    </div>
                    <div class="">
                        <img src="../../../assets/img/gallery-7.webp" alt=""
                            class="img-fluid">
                    </div>
                </div>
                <div class="column_2">
                    <div class="mb_1">
                        <img src="../../../assets/img/gallery-15.webp" alt=""
                            class="img-fluid">
                    </div>
                    <div class="">
                        <img src="../../../assets/img/gallery-1.webp" alt=""
                            class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="video_section row mx-0 mb_10">
        <div class="col-md-7 text_area text-white mb-4 mb-md-0 mb-lg-0">
            <h6 class="f-48 text-a fw-800 mb_1">
                Who we are.
            </h6>
            <p class="f-16 mb_1">
                Orbzone is a platform that provides a networked environment
                for African photographers to share their work with the world.
            </p>
            <p class="f-16 mb_1">
                We enable creative content creators to gain access to better
                and more relatable content that will help them tell the African
                story even better as a business or brand.
            </p>
            <button class="bg-app-primary btn text-white"
                [routerLink]="['/account/join']">Get Started
            </button>
        </div>
        <div class="col-md-5 video_area px-0">
            <video id="my_video" class="video-js vjs-default-skin"
                width="100%" height="auto" controls preload="none"
                poster='../../../assets/img/video_preloader.webp'
                data-setup='{" aspectRatio" :" 640:267" ," playbackRates" : [1,
                1.5, 2] }'>
                <source [src]="videoUrl" type='video/mp4' />
            </video>
        </div>
    </div>

    <div class="steps mb_10">
        <div class="head mb_4">
            <h6 class="f-48 text-white fw-800 mb_2 text-center">
                Becoming an Orbzone Contributor
            </h6>
            <p class="text-center text-white">We made it easy with just 4 steps.</p>
        </div>
        <div class="steps_inside row">
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">01</h6>
                </div>
                <div class="bottom text-center">
                    <img src="../../../assets/icons/login.svg" alt="">
                    <p class="">
                        Log in to <br>
                        www.orbzone.io
                    </p>
                </div>
            </div>
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">02</h6>
                </div>
                <div class="bottom text-center">
                    <img src="./../../../assets/icons/create.svg" alt="">
                    <p class="">
                        Create a <br>
                        free account
                    </p>
                </div>
            </div>
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">03</h6>
                </div>
                <div class="bottom text-center">
                    <img src="../../../assets/icons/upload_img.svg" alt="">
                    <p class="">
                        Upload your <br>
                        best photos
                    </p>
                </div>
            </div>
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">04</h6>
                </div>
                <div class="bottom text-center">
                    <img src="../../../assets/icons/star_4.svg" alt="">
                    <p class="">
                        Follow our <br>
                        guidelines and start <br>
                        earning
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="steps faqs mb_10" id="faq">
        <div class="head mb_4">
            <h6 class="mb_2 f-16 text-center text-white">
                FREQUENTLY ASKED QUESTIONS
            </h6>
            <p class="text-center text-white f-48 fw-800">
                What people frequently <br>
                asked us
            </p>
        </div>
        <div id="accordion">
            <div class="card border_none border_bottom_1x_grey" *ngFor="let item
                of faqs">
                <div class="card-header" id="heading + {{item?.id}}">
                    <h5 class="mb-0">
                        <button class="btn-link border_none bg_none px-0 f-16
                            w-100 text-white flex_x_between"
                            data-toggle="collapse" data-target="#collapse +
                            {{item?.id}}"
                            aria-expanded="true" aria-controls="collapse +
                            {{item?.id}}"
                            (click)="active_tab= item?.id">
                            <span class="pr-4">
                                {{item?.question | titlecase}}
                            </span>
                            <span>
                                <i class="fa fa-plus f-18" aria-hidden="true"
                                    *ngIf="active_tab !== item?.id"></i>
                                <i class="fa fa-minus f-18" aria-hidden="true"
                                    *ngIf="active_tab == item?.id"></i>
                            </span>
                        </button>
                    </h5>
                </div>

                <div id="collapse + {{item?.id}}" class="collapse"
                    [ngClass]="{'show': active_tab == item?.id}"
                    aria-labelledby="heading + {{item?.id}}"
                    data-parent="#accordion">
                    <div class="card-body text-white f-14">
                        <p [ngClass]="{'border_left_2x_primary': active_tab ==
                            item?.id}">
                            {{item?.answer}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="video_section row mx-0 mb_10">
        <div class="col-md-7 text_area text-white mb-4 mb-md-0 mb-lg-0">
            <h6 class="f-48 text-a fw-800 mb_1">
                Now You're Ready
                to Share Your
                Amazing Photos
                With the World!
            </h6>
            <p class="f-16 text-app-grey mb-0">
                But that's not all...
            </p>
        </div>
        <div class="col-md-5 video_area px-0">
            <img src="../../../assets/img/bar.webp" class="img-fluid" alt="">
        </div>
    </div>

    <div class="steps mb_4">
        <div class="head mb_4">
            <h6 class="f-48 text-white fw-800 mb_2 text-center">
                Get Featured on Our Social Media <br>
                Platforms
            </h6>
            <p class="text-center text-white">We love to support the work you
                do.</p>
        </div>
        <div class="steps_inside row">
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">01</h6>
                </div>
                <div class="bottom text-center">
                    <img src="../../../assets/icons/user_icon.svg" alt="">
                    <p class="">
                        Follow us on Instagram,
                        Facebook and Twitter via
                        this handle @orbzone_io
                    </p>
                </div>
            </div>
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">02</h6>
                </div>
                <div class="bottom text-center">
                    <img src="./../../../assets/icons/share.png" alt="">
                    <p class="">
                        Send a minimum of 5 -10
                        photos you want us to
                        feature on our page to
                        orbzoneng@gmail.com
                    </p>
                </div>
            </div>
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">03</h6>
                </div>
                <div class="bottom text-center">
                    <img src="../../../assets/icons/create.svg" alt="">
                    <p class="">
                        On the email, include a brief
                        but interesting description
                        of yourself and include your
                        social media handle for easy
                        tagging.
                    </p>
                </div>
            </div>
            <div class="col-6 col-md-3 mb-3 mb-md-0 text-white">
                <div class="top text-center">
                    <h6 class="f-18 fw-600">Steps</h6>
                    <h6 class="f-36 fw-600">04</h6>
                </div>
                <div class="bottom text-center">
                    <img src="../../../assets/icons/upload_img.svg" alt="">
                    <p class="">
                        Look out for your amazing
                        photo on our social media
                        platforms
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>