import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/Services/api.service';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-like-unlike',
  templateUrl: './like-unlike.component.html',
  styleUrls: ['./like-unlike.component.scss']
})
export class LikeUnlikeComponent implements OnInit {
  id = '';
  loader = false;
  constructor(
    private dialogRef: MatDialogRef<LikeUnlikeComponent>,
    private api: ApiService,
    private util: UtilService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.id = this.data.id;
  }
  async confirm(): Promise<void> {
    try {
      this.loader = true;
      let res: any;
      if (this.data.action === 'like') {
        res = await this.api.likeImage({ imid: this.id });
      }
      if (this.data.action === 'unlike') {
        res = await this.api.unlikeImage({ imid: this.id });
      }
      this.util.succesSnackbar(res.message);
      this.dialogRef.close({ event: 'close', data: res });
      this.loader = false;
    }
    catch (err) {
      this.loader = false;
    }
  }
  cancel(): void {
    this.dialogRef.close({ event: 'close', data: { res: 'response' } });
  }
}
