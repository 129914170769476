import { ApiService } from './../../Services/api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  link = "";
  constructor(
    private dialogRef: MatDialogRef<ShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private util: UtilService,
  ) { }

  ngOnInit(): void {
    this.link = `https://orbzone.io?image=${this.data.imid}`;
  }
  copy(){
    this.util.copyLink(this.link)
  }
}
