<footer class="bg-app-altBlack">
  <div class="footer_links">
    <div class="profile">
      <img src="../../../assets/img/logo.png" alt="" />
      <p class="text-white f-14">No 30 david Ejoor Crescent Gudu Abuja</p>
      <p class="f-14 text-white d-flex flex-wrap">
        <span class="mr-3">+2348074755517</span>
        <!-- <span class="">XXXXXXXXXXXXXX</span> -->
      </p>
      <p class="f-14 text-white">support@orbzone.io</p>
    </div>
    <div class="links">
      <h6 class="font-weight-bold f-16">Links</h6>
      <li [routerLink]="['/account/join']">Become a Contributor</li>
      <li [routerLink]="['/contributors']" [queryParams]="{ tab: 'faq' }">
        F.A.Q
      </li>
    </div>
    <div class="links">
      <h6 class="font-weight-bold f-16">Discover</h6>
      <li [routerLink]="['/contributors']">Contributors</li>
      <li [routerLink]="['/explore']">Premium Images</li>
    </div>
    <div class="links">
      <h6 class="font-weight-bold f-16">Our</h6>
      <li [routerLink]="['/terms']">Privacy Policy</li>
      <li [routerLink]="['/terms']">Terms and Conditions</li>
    </div>
  </div>
  <hr />
  <div class="flex_x_between social">
    <span class="f-13 w-68 text-white"
      >© 2021 Orbzone.io, All Rights Reserved</span
    >
    <div>
      <a href="https://twitter.com/orbzone_io" target="__blank">
        <i class="fab fa-twitter mr-3"></i>
      </a>
      <a href="http://facebook.com/" target="__blank">
        <i class="fab fa-facebook-f mr-3"></i>
      </a>
      <a href="https://www.instagram.com/orbzone_io/" target="__blank">
        <i class="fab fa-instagram mr-3"></i>
      </a>
      <a href="https://www.pinterest.co.uk/orbzone_io/" target="__blank">
        <i class="fab fa-pinterest"></i>
      </a>
    </div>
  </div>
</footer>
