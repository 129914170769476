<app-alt-navbar></app-alt-navbar>
<!-- <div class="banner">
  <div class="image_tabs">
    <span
      [ngClass]="{ active_tab: active_tab == item }"
      class="f-17 text-app-dark fw-800"
      *ngFor="let item of tabs"
      (click)="changeTab(item)"
      >{{ item | titlecase }}
    </span>
  </div>
</div> -->

<!-- POPULAR COLLECTIONS -->
<br /><br />
<div class="collections mb_3 mt-5 pt-5">
  <h6 class="fw-800 f-30 text-app-altBlack">Popular Collections</h6>
  <div class="d-flex align-items-center">
    <span
      class="titles mb-3 cursor mr-3 text-app-grey f-17 radius_5 flex-wrap"
      *ngFor="let item of popular"
      (click)="setCollection(item)"
    >
      {{ item?.name | titlecase }}
    </span>
  </div>
  <div class="row">
    <div
      class="collection_group col-md-4 mb_2 cursor"
      *ngFor="let item of popular"
      (click)="setCollection(item)"
    >
      <div class="featured">
        <img [src]="item?.featured_image" alt="" class="radius_10" />
      </div>
      <div class="others mb_1">
        <img
          [src]="ini"
          alt=""
          class="radius_10"
          *ngFor="let ini of item.initial_images.slice(0, 4)"
        />
      </div>
      <div class="footer flex_x_between">
        <h6 class="f_20 text_app-black font-weight-bold">
          {{ item?.name | titlecase }}
        </h6>
        <div class="d-flex align-items-center">
          <img src="../../../assets/icons/key.svg" alt="" />
          <img src="../../../assets/icons/picture.svg" alt="" class="mx-2" />
          <span class="f_20 text_app-black font-weight-bold">
            {{ item?.count }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center py-5" *ngIf="loaderPopular">
    <i class="fas fa-circle-notch fa-spin text-app-primary f-24"></i>
  </div>
  <div
    class="py-5 text-center mb-5"
    *ngIf="popular.length == 0 && !loaderPopular"
  >
    <h6 class="text-app-black f-20 fw-800">No popular collections available</h6>
  </div>
</div>

<!-- RECOMMENDED COLLECTIONS -->
<div class="collections mb_3">
  <h6 class="fw-800 f-30 text-app-altBlack">Recommended for You</h6>
  <div class="d-flex align-items-center">
    <span
      class="titles mb-3 cursor mr-3 text-app-grey f-17 radius_5"
      *ngFor="let item of recommended"
      (click)="setCollection(item)"
    >
      {{ item?.name | titlecase }}
    </span>
  </div>
  <div class="row">
    <div
      class="collection_group col-md-4 mb_2 cursor"
      *ngFor="let item of recommended"
      (click)="setCollection(item)"
    >
      <div class="featured">
        <img [src]="item?.featured_image" alt="" class="radius_10" />
      </div>
      <div class="others mb_1">
        <img
          [src]="ini"
          alt=""
          class="radius_10"
          *ngFor="let ini of item.initial_images.slice(0, 4)"
        />
      </div>
      <div class="footer flex_x_between">
        <h6 class="f_20 text_app-black font-weight-bold">
          {{ item?.name | titlecase }}
        </h6>
        <div class="d-flex align-items-center">
          <img src="../../../assets/icons/key.svg" alt="" />
          <img src="../../../assets/icons/picture.svg" alt="" class="mx-2" />
          <span class="f_20 text_app-black font-weight-bold">
            {{ item?.count }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center py-5" *ngIf="loaderRecommended">
    <i class="fas fa-circle-notch fa-spin text-app-primary f-24"></i>
  </div>
  <div
    class="py-5 text-center mb-5"
    *ngIf="recommended.length == 0 && !loaderRecommended"
  >
    <h6 class="text-app-black f-20 fw-800">
      No Recommended collections available
    </h6>
  </div>
</div>

<!-- RANDOM COLLECTIONS -->
<div class="collections mb_3">
  <h6 class="fw-800 f-30 text-app-altBlack">Random Collections</h6>
  <div class="d-flex align-items-center">
    <span
      class="titles mb-3 cursor mr-3 text-app-grey f-17 radius_5"
      *ngFor="let item of random"
      (click)="setCollection(item)"
    >
      {{ item?.name | titlecase }}
    </span>
  </div>
  <div class="row">
    <div
      class="collection_group col-md-4 mb_2 cursor"
      *ngFor="let item of random"
      (click)="setCollection(item)"
    >
      <div class="featured">
        <img [src]="item?.featured_image" alt="" class="radius_10" />
      </div>
      <div class="others mb_1">
        <img
          [src]="ini"
          alt=""
          class="radius_10"
          *ngFor="let ini of item.initial_images.slice(0, 4)"
        />
      </div>
      <div class="footer flex_x_between">
        <h6 class="f_20 text_app-black font-weight-bold">
          {{ item?.name | titlecase }}
        </h6>
        <div class="d-flex align-items-center">
          <img src="../../../assets/icons/key.svg" alt="" />
          <img src="../../../assets/icons/picture.svg" alt="" class="mx-2" />
          <span class="f_20 text_app-black font-weight-bold">
            {{ item?.count }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center py-5" *ngIf="loaderRandom">
    <i class="fas fa-circle-notch fa-spin text-app-primary f-24"></i>
  </div>
  <div
    class="py-5 text-center mb-5"
    *ngIf="random.length == 0 && !loaderRandom"
  >
    <h6 class="text-app-black f-20 fw-800">No Random collections available</h6>
  </div>
</div>

<app-footer></app-footer>
