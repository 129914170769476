import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faq } from '../faqs';

@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.scss']
})
export class ContributorsComponent implements OnInit {
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  videoUrl = 'https://res.cloudinary.com/kez/video/upload/v1607437158/orbzone/IMG_1381_lcrrqp.mp4';
  faqs = faq;
  constructor(
    private Activatedroute: ActivatedRoute
  ) { }
  ngOnInit(): void {
    const tab = this.Activatedroute.snapshot.queryParamMap.get('tab');
    if (tab) {
      document.querySelector('#faq').scrollIntoView();
    }
  }
  play(): void {
    const myVideo: any = document.getElementById('my_video');
    myVideo.play();
  }
  stopVideo(): void {
    const myVideo: any = document.getElementById('my_video');
    myVideo.pause();
  }
}
