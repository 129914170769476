import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { gallery } from 'src/app/gallery';
import { AddCollectionComponent } from 'src/app/helper/add-collection/add-collection.component';
import { ImagePreviewDioalgComponent } from 'src/app/helper/image-preview-dioalg/image-preview-dioalg.component';
import { LikeUnlikeComponent } from 'src/app/helper/like-unlike/like-unlike.component';
import { ShareComponent } from 'src/app/helper/share/share.component';
import { UtilService } from 'src/app/Services/util.service';
import { categories } from '../categories';
import { tabs } from '../tabs';
import { ApiService } from './../../Services/api.service';
declare const window: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  active_tab = 'Home';
  search_term = '';
  show_search = true;
  initialLoader = true;
  imageLoaded = false;
  shimmer = false;
  tabs = tabs;
  params = {
    name: '',
    start: '',
    end: '',
  };
  gallery = [];
  categories = [];
  scroll_check = 0;
  pagination = {
    current: 1,
    from: null,
    to: null,
    per_page: null,
    total: null
  };
  constructor(
    private imageService: ApiService,
    private util: UtilService,
    private api: ApiService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private Activatedroute: ActivatedRoute,
    private scroll: ViewportScroller
  ) { }

  ngOnInit(): void {
    const page = this.Activatedroute.snapshot.queryParamMap.get('page');
    const image = this.Activatedroute.snapshot.queryParamMap.get('image');
    if (image && image !== '') {
      this.open({ imid: image });
    }
    if (page && page !== '') {
      this.pagination.current = Number(page);
    }
    this.scroll.scrollToPosition([0, 0]);
    this.util.gallery.subscribe(data => {
      if (data) {
        this.gallery = data;
      }
      else {
        this.publicImages(1);
      }
    });
    this.util.categoryData.subscribe(data => {
      if (data) {
        this.categories = data;
      }
      else {
        this.getCategories();
      }
    });
  }
  // toggling baner search area on window scroll
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const height = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (height < 350) {
      this.show_search = true;
    }
    else {
      this.show_search = false;
    }
    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && this.calcPage() == true) {
    //   // you're at the bottom of the page;
    //   this.scroll_check = this.scroll_check + 1;
    //   if(this.scroll_check == 1){
    //     this.publicImages(this.pagination.current + 1);
    //   }

    // }

  }

  // check if image has finished loading to remove shimmer
  finishedLoading(event: any, index: number): void {
    if (event.returnValue === true) {
      this.imageLoaded = true;
    }
  }
  getCategories(): void {
    this.api.categories().subscribe((res: any) => {
      let data = res.data.categories;
      if (data.length === 0) {
        data = categories;
      }
      this.util.storeCategories(data);
    });
  }

  calcPage(): any {
    const pages = this.pagination.total / this.pagination.per_page;
    if (pages >= this.pagination.current) {
      return true;
    }
  }

  loadMore(): void {
    this.publicImages(this.pagination.current + 1);
  }
  publicImages(page): void {
    this.shimmer = true;
    this.api.searchImage(this.params, page).subscribe((res: any) => {
      if (res.data.images.data.length === 0) {
        this.emptyContentMsg();
      }
      else {
        const data = this.gallery.concat(res.data.images.data);
        this.pagination = {
          current: res.data.images.current_page,
          per_page: res.data.images.per_page,
          to: res.data.images.current_page,
          from: res.data.images.from,
          total: res.data.images.total
        };
        this.util.pageRoute('', res.data.images.current_page);
        this.util.storeGallery(data);
      }
      this.shimmer = false;
    });
  }
  changeTab(item): void {
    this.active_tab = item;
  }
  keypress(): void {
    if (this.search_term.trim().length > 2) {
      setTimeout(() => {
        this.search();
      }, 5000);
    }
  }
  enter(): void {
    this.search();
  }
  search(): void {
    this.util.searchRoute('/search', this.search_term.trim());
  }
  open(item: object): void {
    this.util.open(item, ImagePreviewDioalgComponent);
  }
  addCollection(item: object): void {
    this.util.addCollection(item, this.util.checkRoute(), AddCollectionComponent);
  }
  share(item: object): void {
    this.util.share(item, ShareComponent);
  }
  like(item: any): void {
    const data = {
      title: item.name,
      id: item.imid,
      action: item.i_like === true ? 'unlike' : 'like'
    };
    this.util.likeImage(data, this.util.checkRoute(), LikeUnlikeComponent);
  }

  emptyContentMsg(): void {
    this.snackBar.open('Oops!', 'There are no more images to view', {
      duration: 8000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: ['black-snackbar'],
    });
  }
}
