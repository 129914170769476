import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/Services/util.service';
import { AddCollectionComponent } from '../add-collection/add-collection.component';
import { LikeUnlikeComponent } from '../like-unlike/like-unlike.component';
import { ShareComponent } from '../share/share.component';
import { ApiService } from './../../Services/api.service';

@Component({
  selector: 'app-image-preview-dioalg',
  templateUrl: './image-preview-dioalg.component.html',
  styleUrls: ['./image-preview-dioalg.component.scss']
})
export class ImagePreviewDioalgComponent implements OnInit {
  gallery = [];
  shimmer = false;
  loader = false;
  imageLoaded = false;
  tags = [
    'Asian', 'Background', 'Bowl', 'Chinese', 'Cuisine'
  ];
  params = {
    imid: ''
  };
  constructor(
    private dialogRef: MatDialogRef<ImagePreviewDioalgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private util: UtilService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.params.imid = this.data.imid;
    this.relatedImages();
    this.imageDetails();
  }

  async relatedImages(): Promise<void> {
    this.shimmer = true;
    this.api.relatedImages(this.params).subscribe((res: any) => {
      this.gallery = res.data.images.data.slice(0, 12).sort(() => Math.random() - 0.5);
      this.shimmer = false;
    });
  }

  imageDetails(): void {
    this.api.publicImageDetails(this.params).subscribe((res: any) => {
      this.data = res.data.image;
    });
  }

  async CheckLicence(imid): Promise<void> {
    this.loader = true;
    this.api.checkLicence({ imid }).subscribe((res: any) => {
      this.api.downloadFile(res.data.image.download_link, res.data.image.name);
      this.loader = false;
    }, ((err) => {
      if (err.status === 400 || err.status === 403) {
        this.router.navigate(['/user/invoice', imid]);
      }
      this.loader = false;
      this.dialogRef.close();
    }));
  }

  open(item): void {
    this.data = item;
    document.querySelector('#imageItem').scrollIntoView();
  }
  buyImage(id): void {
    // this.dialogRef.close();
    // this.router.navigate(['/user/invoice', id])
  }
  download(url, filename): void {
    this.api.downloadFile(url, filename);
  }
  addCollection(item: object): void {
    this.util.addCollection(item, this.util.checkRoute(), AddCollectionComponent);
  }
  share(item: object): void {
    this.util.share(item, ShareComponent);
  }
  like(item): void {
    const data = {
      title: item.name,
      id: item.imid,
      action: item.i_like === true ? 'unlike' : 'like'
    };
    this.dialogRef.close();
    this.util.likeImage(data, this.util.checkRoute(), LikeUnlikeComponent);
  }
  // check if image has finished loading to remove shimmer
  finishedLoading(event: any, index: number): void {
    if (event.returnValue === true) {
      this.imageLoaded = true;
    }
  }
}
