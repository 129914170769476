import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { categories } from 'src/app/Core/categories';
import { ApiService } from 'src/app/Services/api.service';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-add-collection',
  templateUrl: './add-collection.component.html',
  styleUrls: ['./add-collection.component.scss']
})
export class AddCollectionComponent implements OnInit {
  CREATE_FORM = true;
  EXISTING_FORM = false;
  loader = false;
  collections = [];
  newForm: FormGroup;
  collectionForm: FormGroup;
  categories = [];
  constructor(
    private MatDialogRef: MatDialogRef<AddCollectionComponent>,
    private api: ApiService,
    private util: UtilService,
    private router: Router,
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.collectionForm = this.formbuilder.group({
      id: ['', Validators.required],
      imid: [''],
    });
    this.newForm = this.formbuilder.group({
      name: ['', Validators.required],
      category: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.mycollections();
    this.util.categoryData.subscribe(data => {
      if (data) {
        this.categories = data;
      }
      else {
        this.getCategories();
      }
    });
  }
  getCategories(): void {
    this.api.categories().subscribe((res: any) => {
      let data = res.data.categories;
      if (data.length === 0) {
        data = categories;
      }
      this.util.storeCategories(data);
    });
  }

  toggleForm(): void {
    this.CREATE_FORM = true;
  }
  close(): void {
    this.MatDialogRef.close();
  }

  async create(): Promise<void> {
    this.loader = true;
    this.api.createCollection(this.newForm.value).subscribe((res: any) => {
      this.util.succesSnackbar(res.message);
      this.mycollections();
      this.addToCollection({
        id: this.getNewestCollection(),
        imid: this.data.imid
      });
      this.newForm.reset();
      this.loader = false;
    }, err => (
      this.loader = false
    ));
  }

  async mycollections(): Promise<void> {
    this.api.myCollections().subscribe((res: any) => {
      this.collections = res.data.collections.data;
      this.util.storeCollections(res.data.collections.data);
    });
  }

  gotoCollections(): void {
    this.close();
    this.router.navigate(['/user'], {
      queryParams: {
        tab: 'collections'
      },
    });
    this.getNewestCollection()
  }

  getNewestCollection(): Promise<number> {
    const filter = this.collections.reduce((a, b) => {
      return new Date(a.created_at) > new Date(b.created_at) ? a : b;
    });
    return filter.id;
  }

  startAddCollection(): void {
    this.collectionForm.get('imid').setValue(this.data.imid);
    this.addToCollection(this.collectionForm.value);
  }

  async addToCollection(data): Promise<void> {
    this.loader = true;
    this.api.addToCollection(data).subscribe((res: any) => {
      this.util.succesSnackbar(res.message);
      this.loader = false;
      this.collectionForm.reset();
    }, err => (
      this.loader = false
    ));
  }
}
