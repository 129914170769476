import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { LikeUnlikeComponent } from '../helper/like-unlike/like-unlike.component';
import { AddCollectionComponent } from './../helper/add-collection/add-collection.component';
import { ShareComponent } from './../helper/share/share.component';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  encryptSecretKey = 'diego';
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private jwtHelper: JwtHelperService,
    private snackBar: MatSnackBar,
    private cookieService: CookieService
  ) { }

  private gallerySubject = new BehaviorSubject<any>('');
  gallery = this.gallerySubject.asObservable();

  private exploreSubject = new BehaviorSubject<any>('');
  explore = this.exploreSubject.asObservable();

  private cardSubject = new BehaviorSubject<any>('');
  cardAdded = this.cardSubject.asObservable();

  private profileSubject = new BehaviorSubject<any>('');
  prifleChange = this.profileSubject.asObservable();

  private categorySubject = new BehaviorSubject<any>('');
  categoryData = this.categorySubject.asObservable();

  private collectionSubject = new BehaviorSubject<any>('');
  collectionData = this.collectionSubject.asObservable();

  private popularSubject = new BehaviorSubject<any>('');
  popularData = this.popularSubject.asObservable();

  private recommendedSubject = new BehaviorSubject<any>('');
  recommendedData = this.recommendedSubject.asObservable();

  private randomSubject = new BehaviorSubject<any>('');
  randomData = this.randomSubject.asObservable();

  storeGallery(data): void {
    this.gallerySubject.next(data);
  }

  storeCategories(data): void {
    this.categorySubject.next(data);
  }

  storeCollections(data): void {
    this.collectionSubject.next(data);
  }

  popularCollections(data): void {
    this.popularSubject.next(data);
  }

  randomCollections(data): void {
    this.randomSubject.next(data);
  }

  recommendedCollections(data): void {
    this.recommendedSubject.next(data);
  }

  storeExplor(data): void {
    this.exploreSubject.next(data);
  }

  setProfileChange(data): void {
    this.profileSubject.next(data);
  }

  detectCardAddition(data): void {
    this.cardSubject.next(data);
  }

  setUserObject(userObject): void {
    this.cookieService.set('/youza', JSON.stringify(userObject), 2);
  }

  getUserObject(): any {
    const user = this.cookieService.get('/youza');
    if (user) {
      return JSON.parse(user);
    }
  }

  // Temporary token generated during pwd reset
  setTempToken(token): void {
    this.cookieService.set('/temp-token', token, 2);
  }
  getTempToken(): string {
    const token = this.cookieService.get('/temp-token');
    if (token) {
      return token;
    }
  }

  // AUTH TOKEN
  setToken(token): void {
    this.cookieService.set('/key', token, 2);
  }
  getToken(): string {
    const token = this.cookieService.get('/key');
    if (token) {
      return token;
    }
  }

  setCollection(data): void {
    localStorage.setItem('public', JSON.stringify(data));
  }

  getCollection(): any {
    const data = localStorage.getItem('public');
    if (data) {
      return JSON.parse(data);
    }
    return;
  }

  isAthourized(allowedUsertypes: string[]): any {
    // check if the list of allowedusertpes for aroute is empty,
    // if empty, authorize the user to access the page
    if (allowedUsertypes == null || allowedUsertypes.length === 0) {
      return true;
    }
    const user = this.getUserObject();
    if (user) {
      const authUsertype = user.role;
      return allowedUsertypes.includes(authUsertype);
    }
  }

  removeUser(): any {
    this.cookieService.deleteAll();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }

  getUserType(): any {
    const token = this.cookieService.get('/key');
    return this.jwtHelper.decodeToken(token).scopes;
  }
  isLoggedIn(): boolean {
    const token = this.cookieService.get('/key');
    if (!token || this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }

  logout(): any {
    this.removeUser();
    this.router.navigate(['/']);
  }

  // NAVIGATION TO SEARCH PAGE
  searchRoute(path, query): any {
    this.router.navigate([path], {
      queryParams: {
        tag: query,
      },
      queryParamsHandling: 'merge',
    });
  }

  pageRoute(path, query): any {
    // this.router.navigate(['']);
    this.router.navigate([path], {
      queryParams: {
        page: query,
      },
    });
  }

  // SNACKBAR METHODS
  succesSnackbar(msg): any {
    this.snackbarConfig('Success', msg, 'success-snackbar');
  }

  errorSnackbar(msg): any {
    this.snackbarConfig('Error', msg, 'error-snackbar');
  }

  warningSnackbar(msg): any {
    this.snackbarConfig('Warning', msg, 'warning-snackbar');
  }

  snackbarConfig(title, msg, theme): any {
    this.snackBar.open(title, msg, {
      duration: 7000,
      verticalPosition: 'top',
      // horizontalPosition: 'right',
      panelClass: [theme],
    });
  }

  // copy text to clipboard
  copyLink(val: string): any {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.succesSnackbar('Image link copied to clipboard');
    this.snackBar.open('Copied!', 'Image link copied to clipboard', {
      duration: 7000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['black-snackbar'],
    });
  }

  // MATERIAL DIALOG HANDLES
  dialogConfig(): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;
    return dialogConfig;
  }
  open(item: object, component: any): void {
    const dialogConfig = this.dialogConfig();
    dialogConfig.data = item;
    dialogConfig.width = '100%';
    this.matDialog.open(component, dialogConfig);
  }
  addCollection(data: object, returnURl: string, component: any): void {
    if (this.isLoggedIn() === true) {
      const dialogConfig = this.dialogConfig();
      dialogConfig.data = data;
      dialogConfig.disableClose = false;
      dialogConfig.width = '38rem';
      this.matDialog.open(component, dialogConfig);
    } else {
      this.router.navigate(['/account/login'], {
        queryParams: {
          returnUrl: returnURl,
        },
      });
    }
  }
  share(data: object, component: any): void {
    const dialogConfig = this.dialogConfig();
    dialogConfig.data = data;
    dialogConfig.disableClose = false;
    dialogConfig.width = '40rem';
    this.matDialog.open(component, dialogConfig);
  }
  likeImage(data: object, returnURl: string, component: any): any {
    if (this.isLoggedIn() === true) {
      const dialogConfig = this.dialogConfig();
      dialogConfig.data = data;
      dialogConfig.disableClose = false;
      dialogConfig.width = '30rem';
      this.matDialog.open(component, dialogConfig);
    } else {
      this.router.navigate(['/account/login'], {
        queryParams: {
          returnUrl: returnURl,
        },
      });
    }
  }

  // Checking for the present route to pass as return url
  checkRoute(): string {
    const url = this.router.url;
    const URL_PATH = url.slice(0, 8); // making sure the url part checked doesnt inckude query
    if (URL_PATH.includes('explore')) {
      return 'explore';
    }
    if (URL_PATH.includes('search')) {
      return 'search';
    }
    if (!URL_PATH.includes('explore') && !url.includes('search')) {
      return 'home';
    }
    return url;
  }

  // Image upload validation (To be used later)
  addAttribute(imageForm: any): void {
    imageForm.attributes.push({ name: '', value: '' });
  }
  validations(imageForm: any): boolean {
    if (imageForm.title === '' || imageForm.description === ''
      || imageForm.type === '' || imageForm.category === '') {
      this.warningSnackbar('Please enter the required fields to continue');
      return false;
    }
    const attr = imageForm.attributes.filter(el => {
      return el.name === '' || el.value === '';
    });
    if (attr.length > 0) {
      this.warningSnackbar('Please enter at least one name and a value for the image attributes');
      return false;
    }
    const tags = imageForm.tags.filter(el => {
      return el.name === '';
    });
    if (tags.length > 0) {
      this.warningSnackbar('Please enter at least one image tag');
      return false;
    }
    return true;
  }

  // check file type in a link
  checkURL(url): string {
    if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      return 'image';
    }
    if (url.match(/\.(mp4|flv|mkv|webm|avi|flv|wmv|mov|mpeg-4|avchd)$/) != null) {
      return 'video';
    }
  }
}
