<mat-dialog-content>
  <div class="top">
    <div class="avatar cursor d-flex align-items-center">
      <img
        [src]="data?.user?.image"
        onerror="this.src='../../../assets/img/user-icon.jpg'"
        alt="Orbzone Author"
      />
      <div class="name text-app-black ml-2">
        <h6 class="f-16 font-weight-bold mb-0">{{ data?.user?.brand_name }}</h6>
        <p class="f-14 mb-0">{{ data?.user?.contribution_count }} Photos</p>
      </div>
    </div>
    <div class="buttons">
      <span class="text-app-black font-weight-bold mr-4"
        >Commercial License</span
      >
      <button
        class="btn bg-app-black text-white f-14"
        (click)="CheckLicence(data?.imid)"
      >
        Download
        <i class="fas fa-circle-notch fa-spin ml-2" *ngIf="loader"></i>
      </button>
    </div>
  </div>

  <!-- FOR SCROLLING INTO VIEW -->
  <div id="imageItem"></div>

  <div class="preview">
    <div class="image">
      <div class="title">
        <h6 class="text-app-black black font-weight-bold f-14">
          {{ data?.name }}
        </h6>
        <p class="font-weight-bold">
          Royalty-free stock photo ID: {{ data?.imid }}
        </p>
      </div>
      <div class="image_data">
        <img
          [src]="data?.preview"
          onerror="this.src='../../../assets/img/unavailable-image.jpg'"
          alt="Orbzone gallery"
          class="w-100"
        />
        <div class="bottom_panel mt-auto">
          <div class="avatar cursor d-flex align-items-center">
            <img
              [src]="data?.user?.image"
              onerror="this.src='../../../assets/img/user-icon.jpg'"
              alt="Orbzone Author"
            />
            <span class="ml-3 f-14 text-white"
              >{{ data?.user?.surname }} {{ data?.user?.firstname }}</span
            >
          </div>
          <div class="icons d-flex">
            <mat-icon (click)="share(data)">share</mat-icon>
            <mat-icon (click)="addCollection(data)"
              >add_circle_outline</mat-icon
            >
            <mat-icon (click)="like(data)">favorite_border</mat-icon>
          </div>
        </div>
      </div>

      <div class="tags">
        <span class="tag mb-2" *ngFor="let item of data?.tags">{{ item }}</span>
      </div>
    </div>

    <div class="intro_text">
      <h6 class="f-16 font-weight-bold">Related Images</h6>
    </div>
    <ngx-masonry [options]="{ gutter: 14, originLeft: true }">
      <div
        ngxMasonryItem
        class="masonry-item"
        *ngFor="let item of gallery; let i = index"
      >
        <img
          (click)="open(item)"
          [src]="item?.preview"
          (load)="finishedLoading($event, i)"
          onerror="this.src='../../../assets/img/unavailable-image.jpg'"
          alt="Orbzone gallery"
          class="w-100 cursor"
        />
        <div class="grid__body cursor"></div>
        <div class="bottom_panel mt-auto">
          <div
            class="avatar cursor d-flex align-items-center"
            (click)="open(item)"
          >
            <img
              [src]="item?.user?.image"
              onerror="this.src='../../../assets/img/user-icon.jpg'"
              alt="Orbzone Author"
            />
            <span class="ml-3 f-14 text-white"
              >{{ item?.user?.surname }} {{ item?.user?.firstname }}</span
            >
          </div>
          <div class="icons d-flex">
            <mat-icon (click)="share(item)">share</mat-icon>
            <mat-icon (click)="addCollection(item)"
              >add_circle_outline</mat-icon
            >
            <mat-icon (click)="like(item)">favorite_border</mat-icon>
          </div>
        </div>
      </div>
    </ngx-masonry>
    <app-shimmer *ngIf="shimmer || !imageLoaded"></app-shimmer>
  </div>
</mat-dialog-content>
